import {
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableRow,
} from "@fluentui/react-components";

import { AttachRegular } from "@fluentui/react-icons";
import { app } from "@microsoft/teams-js";
import { useSelector } from "react-redux";
import { selectMeeting } from "../../reducers/meeting";
// import { environment } from "../../environment";
// import { env } from "process";

export default function DocumentsTab() {
  const meeting = useSelector(selectMeeting);

  // const openDocument = async (document: any) => {
  //   await app.initialize();
  //   const context = await app.getContext();

  //   var encodedWebUrl = encodeURIComponent(
  //     JSON.stringify(environment.TABS_URL + "/#/view-document/" + document.id)
  //   );
  //   var encodedContext = encodeURIComponent(
  //     JSON.stringify({
  //       subEntityId: "/meeting/view-document/" + document.id,
  //       chatId: context?.chat?.id,
  //       contextType: "chat",
  //     })
  //   );
  //   var taskItemUrl =
  //     "https://teams.microsoft.com/l/entity/deeb0189-f14a-4caf-9f90-874811941c9a" +
  //     "/civico-meeting-tab?webUrl=" +
  //     encodedWebUrl +
  //     "&context=" +
  //     encodedContext +
  //     "&openInMeeting=false";

  //   app.openLink(taskItemUrl);
  //   // await app.openLink('https://teams.microsoft.com/l/entity/' + environment.APP_ID + '/civico-meeting-tab?webUrl=' + encodedWebUrl + '&context=' + encodedContext);

  //   // try {
  //   //   const navPromise = await pages.navigateToApp({ appId: environment.APP_ID, pageId: "civico-meeting-tab", webUrl: encodedWebUrl, subPageId: "/meeting/view-document/" + document.id });

  //   // } catch (error) {
  //   //   console.log(error);
  //   // }
  // };

  const openDocumentDialog = async (document: any) => {
    await app.initialize();
    // const context = await app.getContext();

    try {
      // await pages.currentApp.navigateTo({
      //   pageId: "civico-meeting-tab",
      // });
      window.open(document.url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Table arial-label="Documents List" size="small">
        <TableBody>
          {meeting.documents.map((item: any) => (
            <TableRow onClick={() => openDocumentDialog(item)} key={item.id}>
              <TableCell>
                <TableCellLayout media={<AttachRegular />}>
                  {item.title}
                </TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
