import { configureStore } from "@reduxjs/toolkit";
import meetingReducer from "./reducers/meeting";
import raAuth from "./reducers/ra-auth";
import webcast from "./reducers/webcast";

export default configureStore({
  reducer: {
    meeting: meetingReducer,
    raAuth: raAuth,
    webcast: webcast
  },
});
