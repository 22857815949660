import {
  Divider,
  makeStyles,
  mergeClasses,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  ToggleButton,
} from "@fluentui/react-components";
import { meeting } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { environment } from "../../environment";
import {
  selectCurrentUser,
  selectMeeting,
  setMeetingDetails,
  setMeetingStatus, setWebcastStatus,
} from "../../reducers/meeting";
import AgendaTab from "./AgendaTab";
import DocumentsTab from "./DocumentsTab";
import PeopleTab from "./PeopleTab";
import SettingsTab from "./SettingsTab";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1rem",
    height: "100%",
    // alignItems: "stretch",
    boxSizing: "border-box",
  },
  divider: {
    marginTop: "0.5rem",
  },
  tabs: {
    // flexGrow: 0,
  },
  content: {
    // flexGrow: 1,
    height: "90%",
    overflowY: "auto",
  },
  tab: {
    paddingTop: 0,
    paddingLeft: 0,
  },
  rts: {
    // flexShrink: 0,

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "0.5rem",
    alignSelf: "center",
    paddingBottom: "1rem",
  },

  rtsMessage: {},
  btnStatusRTS: {
    backgroundColor: "green",
  },
  btnStatusActive: {
    backgroundColor: "red",
  },
});

export default function MeetingSidePanel() {
  const history = useHistory();
  const styles = useStyles();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const civicoMeeting = useSelector(selectMeeting);
  const currentUser = useSelector(selectCurrentUser);

  const [rtsStatusMessage, setRTSStatusMessage] = useState("");

  // useEffect(() => {
  //   webcastStateRef.current = webcastState;
  // }, [webcastState]);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    switch (data.value) {
      case "tab-agenda":
        history.push("/meeting/");
        break;
      case "tab-documents":
        history.push("/meeting/documents");
        break;
      case "tab-people":
        history.push("/meeting/people");
        break;
      case "tab-settings":
        history.push("/meeting/settings");
        break;
    }
  };

  async function fetchMeeting(tenant: string, joinURL: string) {
    const response = await fetch(
      environment.ADMIN_URL +
        "/msad/meetings/by-joinurl?tenant=" +
        tenant +
        "&url=" +
        encodeURIComponent(joinURL)
    );

    const data = await response.json();

    dispatch(setMeetingDetails(data));

    setTimeout(() => {
      fetchMeeting(tenant, joinURL);
    }, 10000);

    return data;
    // dispatch(setMeeting(data));
  }

  async function fetchMeetingStatus(raMeetingId: string) {
    try {
      const response = await fetch(
        environment.ADMIN_URL + "/api/msad/ra/meeting-status/" + raMeetingId,
        {
          headers: {
            "Bypass-Tunnel-Reminder": "anything",
          },
        }
      );

      const data = await response.json();

      dispatch(setMeetingStatus(data));
    } catch (e) {
    } finally {
      setTimeout(() => {
        fetchMeetingStatus(raMeetingId);
      }, 1000);
    }

    // dispatch(setMeeting(data));
  }

  async function loadData() {
    meeting.getMeetingDetails(
      async (
        error: any,
        meetingDetails: meeting.IMeetingDetailsResponse | null
      ) => {
        if (meetingDetails) {
          const meeting = await fetchMeeting(
            meetingDetails?.organizer.tenantId!,
            meetingDetails?.details?.joinUrl!
          );
          fetchMeetingStatus(meeting.event_id);
        }
      }
    );
  }

  // async function fetchControlStatus(streamId: string) {
  //   try {
  //     const response = await fetch(
  //       environment.ADMIN_URL +
  //         "/api/msad/webcast/status/?stream_id=" +
  //         streamId,
  //       {}
  //     );

  //     const data = await response.json();

  //     if (
  //       data.is_streaming === true &&
  //       webcastStateRef.current.shouldStartStreaming
  //     ) {
  //       dispatch(setShouldStartStreaming(false));
  //     } else if (
  //       data.joined &&
  //       data.is_streaming === false &&
  //       webcastStateRef.current.shouldStartStreaming
  //     ) {
  //       await fetch(environment.ADMIN_URL + "/msad/webcast/start", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           stream_id: streamId,
  //         }),
  //       });
  //     }

  //     dispatch(setWebcastState(data));
  //   } catch (e) {
  //   } finally {
  //     setTimeout(() => {
  //       fetchControlStatus(streamId);
  //     }, 5000);
  //   }
  // }


  async function fetchWebcastStatus(streamId: string) {
    try {
      const response = await fetch(
          environment.ADMIN_URL + "/api/msad/webcast/status?stream_id=" + streamId,
          {
            headers: {
              "Bypass-Tunnel-Reminder": "anything",
            },
          }
      );

      const data = await response.json();

      dispatch(setWebcastStatus(data));
    } catch (e) {
    } finally {
      setTimeout(() => {
        fetchWebcastStatus(streamId);
      }, 1000);
    }

    // dispatch(setMeeting(data));
  }

// eslint-disable-next-line
  useEffect(() => {
    if (currentUser && currentUser.isOP) {
      // eslint-disable-next-line
      fetchWebcastStatus(civicoMeeting.stream_id);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    // async function isAuthenticated() {

    //   try {
    //     const authToken = await authentication.getAuthToken();
    //     console.log(authToken);
    //   } catch (e) {
    //     console.log(e)
    //   }

    // }

    loadData();

    //eslint-disable-next-line
  }, []);

  function clearStatusMessage(timeout: number) {
    setTimeout(() => {
      setRTSStatusMessage("");
    }, timeout);
  }

  async function requestToSpeak() {
    // const { user } = await app.getContext();

    // unmute microphone

    // await app.initialize();

    // meeting.toggleIncomingClientAudio((e, result) => { console.log(e, result); });

    await fetch(environment.ADMIN_URL + "/api/msad/ra/request-to-speak", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        event_id: civicoMeeting.event_id,
        raid: currentUser.id,
      }),
    });

    if (currentUser.isRTS) {
      setRTSStatusMessage("You have been deactivated");
      clearStatusMessage(5000);
    } else if (currentUser.isActive) {
      setRTSStatusMessage("You have been deactivated");
      clearStatusMessage(5000);
    } else {
      setRTSStatusMessage("Your request has been received");
      clearStatusMessage(5000);
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.tabs}>
        <TabList defaultSelectedValue="tab-agenda" onTabSelect={onTabSelect}>
          <Tab className={styles.tab} value="tab-agenda">
            Agenda
          </Tab>
          <Tab className={styles.tab} value="tab-documents">
            Documents
          </Tab>
          <Tab className={styles.tab} value="tab-people">
            People
          </Tab>

          {currentUser?.isOP && (
            <Tab className={styles.tab} value="tab-settings">
              Control
            </Tab>
          )}
        </TabList>
        <div className={styles.divider}>
          <Divider appearance="subtle"></Divider>
        </div>
      </div>

      <div className={styles.content}>
        <Switch>
          <Route exact path={`${path}`} component={AgendaTab} />
          <Route exact path={`${path}/documents`} component={DocumentsTab} />
          <Route exact path={`${path}/people`} component={PeopleTab} />
          <Route exact path={`${path}/settings`} component={SettingsTab} />
        </Switch>
      </div>

      {civicoMeeting.discussion_mode === "request" && (
        <div className={styles.rts}>
          <span className={mergeClasses(styles.rtsMessage)}>
            {rtsStatusMessage}
          </span>

          <ToggleButton
            className={mergeClasses(
              currentUser?.isRTS ? styles.btnStatusRTS : "",
              currentUser?.isActive ? styles.btnStatusActive : ""
            )}
            onClick={() => {
              requestToSpeak();
            }}
            size="large"
          >
            {currentUser.isRTS
              ? "Withdraw request"
              : currentUser.isActive
              ? "Deactivate"
              : "Request to speak"}
          </ToggleButton>
        </div>
      )}
    </div>
  );
}
