import { createSlice } from "@reduxjs/toolkit";

export enum TeamsWebcastStatus {
  STREAMING = "STREAMING",
  JOINED = "JOINED",
  STOPPED = "STOPPED",
  PAUSED = "PAUSED",
  UNSET = "UNSET",
}

export const meeting = createSlice({
  name: "meeting",
  initialState: {
    date: new Date(),
    title: "",
    agenda: [],
    participants: [],
    documents: [],
    event_id: null,
    stream_id: null,
    discussion_mode: "",
    teamsUserId: null,
    currentUser: {
      id: 0,
      isRTS: false,
      isActive: false,
      name: "",
      msid: "",
      role: "",
      isOP: false,
    },

    status: {
      active_agenda_id: null,
      online_participants: Array<number>(),
      rts_participants: Array<number>(),
      active_participants: Array<number>(),
      teams_webcast_status: TeamsWebcastStatus.UNSET,
      streaming_start_time: 0,
    },

    loaded: false,
  },
  reducers: {
    setTeamsUser: (state, action) => {
      state.teamsUserId = action.payload;
    },
    setMeetingDetails: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const payload = action.payload;

      state.stream_id = payload.stream_id;
      state.event_id = payload.event_id;
      state.participants = payload.participants.sort((a: any, b: any) =>
        a.name.localeCompare(b.name)
      );
      state.title = payload.title;
      state.agenda = payload.agenda;
      state.documents = payload.documents;
      state.discussion_mode = payload.discussion_mode;

      const currentUser = payload.participants.find((item: any) => {
        if (state.teamsUserId) {
          return state.teamsUserId === item.msuser?.msid;
        }

        return null;
      });

      if (currentUser) {
        state.currentUser.id = currentUser.raid;
        state.currentUser.name = currentUser.name;
        state.currentUser.msid = currentUser.msuser?.msid;
        state.currentUser.role = currentUser.role;
        state.currentUser.isOP =
          currentUser.role === "OP" ||
          currentUser.role === "CHAIR" ||
          currentUser.role === "CO-CHAIR";
      }

      state.date = payload.date;

      state.loaded = true;
    },

    setWebcastStatus: (state, action) => {
      const payload = action.payload;

      if (payload.teams_meeting_joined) {
        if (payload.teams_is_streaming) {

          if (payload.teams_is_paused) {
            state.status.teams_webcast_status = TeamsWebcastStatus.PAUSED;
          } else {
            state.status.teams_webcast_status = TeamsWebcastStatus.STREAMING;
          }

        } else {
          state.status.teams_webcast_status = TeamsWebcastStatus.JOINED;
        }
      } else {
        state.status.teams_webcast_status = TeamsWebcastStatus.STOPPED;
      }

      state.status.streaming_start_time = payload.teams_streaming_start_time;
    },

    setMeetingStatus: (state, action) => {
      const payload = action.payload;

      state.status.online_participants = payload.online_participants;
      state.status.rts_participants = payload.rts_participants;
      state.status.active_participants = payload.active_participants;
      state.status.active_agenda_id = payload.active_agenda_id;



      if (state.currentUser) {
        const currentUserRaID = state.currentUser.id;

        // @ts-ignore
        state.currentUser.isRTS =
          state.status.rts_participants.indexOf(currentUserRaID) > -1;
        // @ts-ignore
        state.currentUser.isActive =
          state.status.active_participants.indexOf(currentUserRaID) > -1;
      }
    },
  },
});

export const { setMeetingDetails, setMeetingStatus, setTeamsUser, setWebcastStatus } =
  meeting.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectMeeting = (state: any) => state.meeting;
export const selectAgenda = (state: any) => state.meeting.agenda;
export const selectOnlineParticipants = (state: any): Array<number> =>
  state.meeting.status.online_participants;
export const selectActiveParticipants = (state: any): Array<number> =>
  state.meeting.status.active_participants;
export const selectRTSParticipants = (state: any): Array<number> =>
  state.meeting.status.rts_participants;
export const selectCurrentUser = (state: any): any => state.meeting.currentUser;
export const selectActiveAgenda = (state: any): Array<number> =>
  state.meeting.status.active_agenda_id;

export const selectTeamsWebcastStatus = (state: any): any => {
  return {
    status: state.meeting.status.teams_webcast_status,
    streaming_start_time: state.meeting.status.streaming_start_time,
  };
};

export default meeting.reducer;
