import {
    CounterBadge,
    Divider,
    makeStyles,
    SelectTabData,
    SelectTabEvent,
    shorthands,
    Tab,
    TabList,
    tokens
} from "@fluentui/react-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
    Route,
    Switch,
    useHistory
} from "react-router-dom";
import { selectMeeting } from "../../reducers/meeting";
import AgendaTab from "./AgendaTab";
import DocumentsTab from "./DocumentsTab";
import PeopleTab from "./PeopleTab";

const useStyles = makeStyles({

    tabs: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: tokens.colorNeutralBackground1,
        rowGap: "20px",
        ...shorthands.padding("20px", "20px"),
    },

    tabList: {
        // paddingTop: 0,
        width: "15%"
    },

    tab: {

    },

    divider: {
        flexGrow: 1,
    },


    content: {
        flexGrow: 2,
        ...shorthands.padding("0", "20px"),
    },
});

export default function MeetingTabs() {
    const history = useHistory();
    const meetingData = useSelector(selectMeeting);

    const [currentTab, setCurrentTab] = useState(history.location.pathname);


    history.listen((loc, action) => {
        setCurrentTab(loc.pathname);
    });


    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        history.push(data.value + "");
    };

    const styles = useStyles();

    return (


        <div className={styles.tabs}>

            <TabList className={styles.tabList} defaultSelectedValue="/meeting" selectedValue={currentTab} onTabSelect={onTabSelect} vertical>
                <Tab className={styles.tab} value="/meeting">
                    Agenda <CounterBadge appearance="filled" size="small" color="informative" count={meetingData.agenda.length} />
                </Tab>
                <Tab className={styles.tab} value="/meeting/documents">
                    Documents <CounterBadge appearance="filled" size="small" color="informative" count={meetingData.documents.length} />
                </Tab>
                <Tab className={styles.tab} value="/meeting/people">
                    People <CounterBadge appearance="filled" size="small" color="informative" count={meetingData.participants.length} />
                </Tab>

            </TabList>

            <div className={styles.divider}>
                <Divider appearance="subtle" vertical style={{ height: "100%" }}></Divider>
            </div>

            <div className={styles.content}>
                <Switch>
                    <Route exact path={'/meeting'} component={AgendaTab} />
                    <Route exact path={'/meeting/documents'} component={DocumentsTab} />
                    <Route exact path={'/meeting/people'} component={PeopleTab} />
                </Switch>
            </div>


        </div>
    );
}
