import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  makeStyles,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Persona,
  PresenceBadgeStatus,
  tokens
} from "@fluentui/react-components";
import { MoreCircleRegular } from "@fluentui/react-icons";
import { useSelector } from "react-redux";
import { environment } from "../../environment";
import { selectActiveParticipants, selectCurrentUser, selectMeeting, selectOnlineParticipants, selectRTSParticipants } from "../../reducers/meeting";

const useStyles = makeStyles({
  peoplelist: {
    display: "flex",
    rowGap: "1rem",
    flexDirection: "column",
    paddingTop: "1rem",
    paddingBottom: "1rem"
  },
  row: {
    display: "flex",
    rowGap: "1rem",
    columnGap: "1rem",
    alignItems: "center",
  },
  avatar: {
    flexGrow: 1
  },
  center: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  subtext: {
    fontSize: tokens.fontSizeBase200,
  },
  personRow: {
    display: "flex",

  },
  opMenu: {
    display: "flex",
    alignContent: "flex-end",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  }
});

export default function PeopleTab() {
  const civicoMeeting = useSelector(selectMeeting);
  const onlineParticipants = useSelector<null, Array<number>>(selectOnlineParticipants)
  const rtsParticipants = useSelector<null, Array<number>>(selectRTSParticipants)
  const activeParticipants = useSelector<null, Array<number>>(selectActiveParticipants)
  const currentUser = useSelector(selectCurrentUser);

  const styles = useStyles();


  const allMembersRows: Array<any> = [];
  const activeMembersRows: Array<any> = [];
  const rtsMembersRows: Array<any> = [];


  async function clearAll() {

  }


  async function acceptRTS(raid: string) {


    await fetch(
      environment.ADMIN_URL + "/api/msad/ra/operator/accept-rts",
      {
        method: 'POST',
        headers: {

          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          event_id: civicoMeeting.event_id,
          raid: raid,

        })
      }
    );

  }

  async function deactivateRTS(raid: string) {


    await fetch(
      environment.ADMIN_URL + "/api/msad/ra/operator/deactivate-rts",
      {
        method: 'POST',
        headers: {

          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          event_id: civicoMeeting.event_id,
          raid: raid,

        })
      }
    );

  }


  civicoMeeting.participants.forEach((participant: any) => {


    let status: PresenceBadgeStatus = "offline";

    if (onlineParticipants.indexOf(participant.raid) > -1) {
      status = "available"
    }

    console.log(status);

    const isRequestingToSpeak = rtsParticipants.indexOf(participant.raid) > -1;
    const isActive = activeParticipants.indexOf(participant.raid) > -1;

    const memberRow = (
      <div className={styles.personRow} key={participant.raid}>
        <Persona
          className={styles.avatar}

          name={participant.name}
          // secondaryText="Member"
          // tertiaryText="Venue"
          // presence={{ status: status }}
          avatar={{

          }} />
        {(currentUser?.isOP) &&
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton appearance="transparent" icon={<MoreCircleRegular />}></MenuButton>
            </MenuTrigger>


            <MenuPopover>
              <MenuList>

                {!isActive && <MenuItem onClick={() => { acceptRTS(participant.raid) }} >Activate</MenuItem>}

                {isRequestingToSpeak && <MenuItem onClick={() => { acceptRTS(participant.raid) }} >Accept Request</MenuItem>}



                {/* {(!isRequestingToSpeak && !isActive) && <MenuItem>Request to Speak</MenuItem>} */}

                {isActive && <MenuItem onClick={() => { deactivateRTS(participant.raid) }}>Deactivate</MenuItem>}

              </MenuList>

            </MenuPopover>

          </Menu>
        }

      </div>


    );

    if (isRequestingToSpeak) {
      rtsMembersRows.push(memberRow);
    }

    if (isActive) {
      activeMembersRows.push(memberRow);
    }

    allMembersRows.push(memberRow);
  });

  return (
    <div >
      {(currentUser && currentUser.isOP) &&
        <div className={styles.opMenu}>
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton appearance="transparent" icon={<MoreCircleRegular />}></MenuButton>
            </MenuTrigger>


            <MenuPopover>
              <MenuList>

                <MenuItem onClick={() => { clearAll() }} >Clear all</MenuItem>
              </MenuList>

            </MenuPopover>

          </Menu>

        </div>

      }


      <Accordion defaultOpenItems="3" collapsible>
        <AccordionItem value="1">
          <AccordionHeader>Active speakers ({activeMembersRows.length})</AccordionHeader>
          <AccordionPanel>
            <div className={styles.peoplelist}>
              {activeMembersRows}
            </div>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="2">
          <AccordionHeader>Requesting to speak ({rtsMembersRows.length})</AccordionHeader>
          <AccordionPanel>
            <div className={styles.peoplelist}>
              {rtsMembersRows}
            </div>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="3">
          <AccordionHeader>Attendees ({allMembersRows.length})</AccordionHeader>
          <AccordionPanel>
            <div className={styles.peoplelist}>
              {allMembersRows}
            </div>

          </AccordionPanel>
        </AccordionItem>

      </Accordion>
    </div>
  );
}
