
import { ArrowPrevious16Filled } from "@fluentui/react-icons";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { selectMeeting } from "../../reducers/meeting";

import { Button, Text, makeStyles, shorthands } from "@fluentui/react-components";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles({
    root: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100%",
        flexGrow: 1,
        ...shorthands.padding("20px", "0"),
    },
    header: {
        display: "flex",
        flexDirection: "row",
        columnGap: "1rem",
        alignItems: "center",
    },


    content: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },

    page: {
        marginTop: "1rem",
        width: "100%"
    }

});


export default function DocumentViewer() {


    const meeting = useSelector(selectMeeting);

    const [meetingDoc, setDocument] = useState<any>(null);
    const [contentWidth, setContentWidth] = useState<any>(null);
    const [numPages, setNumPages] = useState<any>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    let { docId }: any = useParams();

    const history = useHistory();

    useEffect(() => {
        const doc = meeting.documents.find((item: any) => {
            return parseInt(item.id) === parseInt(docId);
        });

        setDocument(doc);

    }, [meeting.documents, docId]);

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
    }

    function resizeDocumentPages() {
        if (contentRef && contentRef.current) {
            let width = contentRef.current.offsetWidth;
            if (width > 1500) {
                width = 1500;
            }
            setContentWidth(width);
        }
    }

    function closeViewer() {
        history.goBack();
    }


    useLayoutEffect(() => {
        function handleResize() {

            resizeDocumentPages();

        }

        window.addEventListener('resize', handleResize)

        resizeDocumentPages();
    })

    const styles = useStyles();
    return (
        <div>
            {meetingDoc &&
                <div className={styles.root}>


                    <div className={styles.header}>
                        <Button onClick={() => closeViewer()} icon={<ArrowPrevious16Filled />} />
                        <Text size={500} >{meetingDoc.title}</Text>

                    </div>

                    <div className={styles.content} ref={contentRef}>


                        <Document onLoadSuccess={onDocumentLoadSuccess} file={meetingDoc?.url} >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page width={contentWidth} className={styles.page} renderTextLayer={false} renderAnnotationLayer={false} key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>


                    </div>

                </div>
            }
        </div>
    );
}
