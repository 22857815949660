import { createSlice } from "@reduxjs/toolkit";

export const raAuth = createSlice({
  name: "raauth",
  initialState: {
    sessionId: null
  },
  reducers: {
    setSessionID: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const payload = action.payload;

      state.sessionId = payload;
    },
  },
});

export const { setSessionID } = raAuth.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSessionID = (state: any) => state.raauth.sessionId;


export default raAuth.reducer;
