import { useTeams } from "@microsoft/teamsfx-react";
import { Redirect, Route, HashRouter as Router } from "react-router-dom";
import "./App.css";
import Privacy from "./Privacy";
import Tab from "./Tab";
import TabConfig from "./TabConfig";
import TermsOfUse from "./TermsOfUse";

import {
  FluentProvider,
  makeStyles,
  teamsDarkTheme,
  teamsLightTheme,
  tokens,
} from "@fluentui/react-components";
import MeetingSidePanel from "./side-panel/MeetingSidePanel";

import { FrameContexts, app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import MeetingPanel from "./meeting-channel/MeetingPanel";
import CastVotePanel from "./side-panel/CastVotePanel";
import { setTeamsUser } from "../reducers/meeting";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  button: {},
  provider: {
    backgroundColor: tokens.colorNeutralBackground3,
  },
  text: {},
});

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const teams = useTeams({})[0];
  const theme = teams.themeString === "dark" ? teamsDarkTheme : teamsLightTheme;
  const styles = useStyles();
  const dispatch = useDispatch();

  const [frameContext, setFrameContext] = useState<FrameContexts>();

  useEffect(() => {
    app.initialize().then(async () => {
      app.notifySuccess();
      app.notifyAppLoaded();

      const frameContext = await app.getFrameContext();
      setFrameContext(frameContext);

      const { user } = await app.getContext();

      dispatch(setTeamsUser(user?.id));
    });

    //eslint-disable-next-line
  }, []);

  return (
    <FluentProvider className={styles.provider} theme={theme}>
      <Router>
        <Route exact path="/">
          <Redirect to="/meeting" />
        </Route>
        <>
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/termsofuse" component={TermsOfUse} />
          <Route exact path="/tab" component={Tab} />
          <Route exact path="/config" component={TabConfig} />

          {frameContext === "sidePanel" && (
            <Route path="/meeting" component={MeetingSidePanel} />
          )}

          {frameContext === "content" && (
            <Route path="/meeting" component={MeetingPanel} />
          )}

          <Route path="/cast-vote/:voteId" component={CastVotePanel} />
        </>
      </Router>
    </FluentProvider>
  );
}
