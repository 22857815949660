import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableRow
} from "@fluentui/react-components";
import { CalendarAgendaRegular } from "@fluentui/react-icons";
import * as React from "react";
import { useSelector } from "react-redux";
import { selectMeeting } from "../../reducers/meeting";

export default function AgendaTab() {
  const meeting = useSelector(selectMeeting);

  const [open, setOpen] = React.useState(false);
  const [selectedAgenda, setSelectedAgenda] = React.useState<any>(false);

  function viewAgendaDetails(agenda: any) {
    setOpen(true);
    setSelectedAgenda(agenda);
  }

  return (
    <div>
      <Table arial-label="Agenda List" size="small">
        <TableBody>
          {meeting.agenda.map((item: any) => (
            <TableRow onClick={() => { viewAgendaDetails(item) }} key={item.id}>
              <TableCell>
                <TableCellLayout media={<CalendarAgendaRegular />}>
                  {item.title}

                </TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>

        <DialogSurface>
          <DialogBody>
            <DialogTitle>{selectedAgenda?.title}</DialogTitle>
            <DialogContent>
              {selectedAgenda?.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}
