import {
  Avatar,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableRow,
  tokens
} from "@fluentui/react-components";
import { useSelector } from "react-redux";
import { selectMeeting } from "../../reducers/meeting";

const useStyles = makeStyles({
  root: {
    display: "flex",
    rowGap: "1rem",
    flexDirection: "column",
    width: "100%"
  },
  row: {
    display: "flex",
    rowGap: "1rem",
    columnGap: "1rem",
    alignItems: "center",
  },
  avatar: {},
  subtext: {
    fontSize: tokens.fontSizeBase200,
  },
});

export default function PeopleTab() {
  const meeting = useSelector(selectMeeting);

  const styles = useStyles();

  const participantRows = meeting.participants.map((participant: any) => {
    return (

      <TableRow key={participant.id}>
        <TableCell>
          <TableCellLayout media={<Avatar
            className={styles.avatar}
            name={participant.name}
          />}>
            {participant.name}
          </TableCellLayout>
        </TableCell>
      </TableRow>


    );
  });

  return <div>
    <Table arial-label="People List" size="small">
      <TableBody>
        {participantRows}
      </TableBody>
    </Table>

  </div>;
}
