import { createSlice } from "@reduxjs/toolkit";

export const webcast = createSlice({
  name: "webcast",
  initialState: {

    joined: false,
    is_streaming: false,
    shouldStartStreaming: false,

    loaded: false
  },
  reducers: {

    setWebcastState: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const payload = action.payload;

      state.joined = payload.joined;
      state.is_streaming = payload.is_streaming;

      state.loaded = true;
    },

    setShouldStartStreaming: (state, action) => {
      state.shouldStartStreaming = action.payload;
    }

  },
});

export const { setWebcastState, setShouldStartStreaming } = webcast.actions;

export const selectWebcastState = (state: any) => state.webcast;
export const selectShouldStartStreaming = (state: any) => state.webcast.shouldStartStreaming;

export default webcast.reducer;
