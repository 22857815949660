import {
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableRow
} from "@fluentui/react-components";

import { AttachRegular } from "@fluentui/react-icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectMeeting } from "../../reducers/meeting";

export default function DocumentsTab() {
  const meeting = useSelector(selectMeeting);

  const history = useHistory();

  const openDocument = (document: any) => {
    // window.open(document.url+'?inline=true', '_blank'); 
    // app.openLink(document.url+'?inline=true');
    history.push('/meeting/view-document/' + document.id);
    // pages.currentApp.navigateToDefaultPage();
  }

  return (
    <div>
      <Table arial-label="Documents List" size="small">
        <TableBody>
          {meeting.documents.map((item: any) => (
            <TableRow onClick={() => openDocument(item)} key={item.id}>
              <TableCell>
                <TableCellLayout media={<AttachRegular />}>
                  {item.title}
                </TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
