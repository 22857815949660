import {
  Text,
  makeStyles,
  shorthands
} from "@fluentui/react-components";
import { app, meeting } from "@microsoft/teams-js";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory
} from "react-router-dom";
import { selectMeeting, setMeetingDetails } from "../../reducers/meeting";
import DocumentViewer from "./DocumentViewer";
import MeetingTabs from "./MeetingTabs";
import { environment } from "../../environment";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flexWrap: "nowrap"
  },
  header: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    ...shorthands.padding("20px", "20px"),
  },
  headerLogo: {
    width: "5rem",
  },
  meetingTitle: {
    marginRight: "10px"
  },
  meetingDate: {
    color: "#aaa",
    fontSize: "0.8rem"
  },

  content: {
    ...shorthands.padding("0", "20px"),
  },
});

export default function MeetingPanel() {
  const history = useHistory();
  const styles = useStyles();
  const dispatch = useDispatch();
  const meetingData = useSelector(selectMeeting);


  async function fetchMeeting(tenant: string, joinURL: string) {

    try {

      const response = await fetch(
        environment.ADMIN_URL + "/msad/meetings/by-joinurl?tenant=" + tenant + "&url=" + joinURL,
        {
          headers: {
            'Bypass-Tunnel-Reminder': 'anything'
          }
        }
      );

      const data = await response.json();

      dispatch(setMeetingDetails(data));

    } catch (e) {

    } finally {
      setTimeout(() => {
        fetchMeeting(tenant, joinURL)
      }, 2000);
    }



    // dispatch(setMeeting(data));
  }



  async function redirectDoc() {

    const context = await app.getContext();
    if (context.page.subPageId) {
      history.push(context.page.subPageId + "");
    }

  }

  async function loadData() {

    meeting.getMeetingDetails(async (error: any, meetingDetails: meeting.IMeetingDetailsResponse | null) => {

      if (meetingDetails) {
        fetchMeeting(meetingDetails?.organizer.tenantId!, meetingDetails?.details?.joinUrl!);
      }


    });

  }


  useEffect(() => {



    // async function isAuthenticated() {

    //   try {
    //     const authToken = await authentication.authenticate({
    //       url: window.location.origin,
    //       width: 600,
    //       height: 535
    //     });
    //     console.log(authToken);
    //   } catch (e) {
    //     console.log(e);
    //   }



    // }


    // isAuthenticated();
    redirectDoc();
    loadData();
    //eslint-disable-next-line
  }, []);



  return (
    <div className={styles.root}>

      <div className={styles.header}>

        <div>
          <Text size={500} className={styles.meetingTitle}>{meetingData.title}</Text>
          <Text className={styles.meetingDate}>{moment(meetingData.date).format('LLLL')}</Text>
        </div>

        {/* <img className={styles.headerLogo} src="https://civico.io/wp-content/themes/civico_2020/assets/logo_purp.svg" /> */}
      </div>

      <div className={styles.content}>
        <Switch>
          <Route path={'/meeting/view-document/:docId'} component={DocumentViewer} />

          <Route path={'/meeting'} component={MeetingTabs} />
        </Switch>
      </div>

    </div>
  );
}
