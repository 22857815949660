import {
  Button,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens
} from "@fluentui/react-components";
import { app } from "@microsoft/teams-js";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as React from "react";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { environment } from "../../environment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1rem",
    alignItems: "stretch",
    height: "90%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginTop: "1rem",
    // paddingTop: "1rem",
    // paddingBottom: "1rem"
  },
  content: {
    flexGrow: 1,

  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "baseline",
  },

  timer: {
    marginLeft: "auto"
  },
  castButtons: {
    display: "flex",
    justifyContent: "center",
    gridRowGap: tokens.spacingVerticalS,
    rowGap: "10px",
    columnGap: "1rem",
  },

  results: {
    display: "flex",
    height: "100%",
    alignItems: "end"
  },

  resultsBar: {
    width: "25%",
    backgroundColor: "red",
    height: "100%",
    position: "relative"
  },

  yesButton: {
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderColor("green")

  },
  noButton: {
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderColor("red")
  },
  abstainButton: {
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderColor("yellow")
  },
  selectedChoice: {
    ...shorthands.borderWidth("3px")
  }
});



export default function CastVotePanel() {

  let { voteId }: any = useParams();
  // let voteId = "7";
  app.initialize().then(() => {
    app.notifyAppLoaded();
  });

  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(0);
  const [choice, setChoice] = useState<string>('');


  const isActiveRef = React.useRef(isActive)
  const isPausedRef = React.useRef(isPaused)

  React.useEffect(() => {
    let interval: any = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  function startTimer() {

    setTime(0);
    isActiveRef.current = true;
    isPausedRef.current = true;
    setIsActive(true);
    setIsPaused(false);
  }

  function stopTimer() {

    isActiveRef.current = false;
    isPausedRef.current = true;
    setIsActive(false);
    setIsPaused(true);
    // setTime(0);
  }


  const styles = useStyles();

  const [vote, setVote] = useState<any>({ title: '', results: [], mappedResults: [0, 0, 0, 0] });

  useEffect(() => {


    async function fetchVote(voteId: string) {
      const response = await fetch(
        environment.ADMIN_URL + "/api/msad/ra/vote/" + voteId
      );

      const data = await response.json();

      const mappedResults = data.results.reduce((acc: Array<number>, cur: any) => {

        switch (cur.choice) {
          case 'yes':
            acc[0] += 1;
            break;
          case 'no':
            acc[1] += 1;
            break;
          case 'abstain':
            acc[2] += 1;
            break;
        }

        return acc;

      }, [0, 0, 0, 0]);


      data.mappedResults = mappedResults;
      setVote(data);
      if (data.vote_status === 'STARTED' && !isActiveRef.current) {
        startTimer();
      } else if (data.vote_status !== 'STARTED' && isActiveRef.current) {
        stopTimer();
      }

      setTimeout(() => {
        fetchVote(voteId);
      }, 1000);
    }





    fetchVote(voteId);

    //eslint-disable-next-line
  }, []);

  const data = {
    labels: ["Yes", "No", "Abstain", "DNV"],
    datasets: [{
      label: '',
      data: vote.mappedResults,
      borderRadius: 10,
      backgroundColor: ["#3bc48f", "#fd0404", "#020efc", "#616161"],
    }]
  }




  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        border: {
          display: false
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false
        },
        ticks: {
          display: true
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false
        },
        ticks: {
          display: false
        }
      }
    },
    plugins: {

      legend: {
        display: false
      },
      datalabels: {
        display: true,
        color: '#FFFFFF'
      },
      title: {
        display: false,
        text: '',
      },

    },
  }

  async function castVote(choice: string) {

    setChoice(choice);

    const { user } = await app.getContext();

    await fetch(
      environment.ADMIN_URL + "/api/msad/ra/vote/" + voteId + "/cast",
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Bypass-Tunnel-Reminder': 'anything'
        },
        body: JSON.stringify({
          "choice": choice,
          "msid": user?.id,
          "session_id": vote.session.voting_session_id
        })
      }
    );



  }



  return (
    <div className={styles.root}>

      <div className={styles.header}>
        <h3>{vote.title}</h3>
        <span className={styles.timer}>
          <span>
            {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
          </span>
          <span>
            {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
          </span>
        </span>
      </div>
      <div className={styles.content}>


        {(vote.session && vote.vote_status === "STARTED") && <div className={styles.castButtons}>
          <Button onClick={() => { castVote('yes') }} className={mergeClasses(styles.yesButton, (choice === 'yes') ? styles.selectedChoice : '')} shape="circular" size="large">Yes</Button>
          <Button onClick={() => { castVote('no') }} className={mergeClasses(styles.noButton, (choice === 'no') ? styles.selectedChoice : '')} shape="circular" size="large">No</Button>
          <Button onClick={() => { castVote('abstain') }} className={mergeClasses(styles.abstainButton, (choice === 'abstain') ? styles.selectedChoice : '')} shape="circular" size="large">Abstain</Button>
        </div>}

        {(vote.session && vote.vote_status === "FINISHED") && <div className={styles.results}>

          <Bar options={chartOptions} data={data}>

          </Bar>

          {/* <div className={styles.resultsBar}>
            <span>31</span>
          </div> */}
        </div>}
      </div>


    </div>
  );
}
