import React, { useEffect } from "react";
import "./App.css";
import { app, meeting, pages } from "@microsoft/teams-js";
import { environment } from "../environment";

/**
 * The 'Config' component is used to display your group tabs
 * user configuration options.  Here you will allow the user to
 * make their choices and once they are done you will need to validate
 * their choices and communicate that to Teams to enable the save button.
 */
export default function MeetingConfig() {


  const [error, setError] = React.useState<boolean>(false);
  const [errorType, setErrorType] = React.useState<string>('');
  const [civicoMeeting, setCivicoMeeting] = React.useState<any>(null);

  async function verifyTenant() {
    const context = await app.getContext();

    const user = context.user;

    if (!user) {
      return;
    }

    const tenantId = user.tenant?.id;

    try {
      const response = await fetch(`${environment.ADMIN_URL}/api/msad/verify-tenant`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          tenant: tenantId
        })
      });

      const data = await response.json();

      if (response.status === 200 || data.success) {
        console.log('[Civico] Tenant is registered');
        return true;
      } else {
        console.log('[Civico] Tenant is not registered');
        return false;
      }
    } catch (e) {
      return false;
    }



  }

  async function loadMeeting(): Promise<any> {

    const context = await app.getContext();

    const user = context.user;


    const tenantId = user?.tenant?.id;

    return new Promise<any>((resolve, reject) => {
      meeting.getMeetingDetails(async (error: any, meetingDetails: meeting.IMeetingDetailsResponse | null) => {



        if (error) {
          resolve(null);
          return;
        }

        try {
          const response = await fetch(
            environment.ADMIN_URL + "/msad/meetings/by-joinurl?tenant=" + tenantId + "&url=" + encodeURIComponent(meetingDetails?.details.joinUrl!),
          );

          const data = await response.json();


          resolve(data);
        } catch (e) {
          resolve(false);
        }



      });
    });




  }

  async function onSave(saveEvent: any) {

    let baseUrl = "";
    if (window.location.port) {
      baseUrl = `https://${window.location.hostname}:${window.location.port}`;
    } else {
      baseUrl = `https://${window.location.hostname}`;
    }

    try {
      await pages.config.setConfig({
        suggestedDisplayName: "Civico",
        entityId: "civico-meeting-tab",
        contentUrl: baseUrl + "/#/meeting",
        websiteUrl: baseUrl + "/#/meeting",
      });



      saveEvent.notifySuccess();
    } catch (e) {
      console.error(e);
      saveEvent.notifyFailure();
    }

  }

  useEffect(() => {
    // Initialize the Microsoft Teams SDK
    app.initialize().then(async () => {


      setError(false);
      setErrorType('');

      pages.config.registerOnSaveHandler(onSave);


      const context = await app.getContext();

      const user = context.user;


      if (!user) {

        console.log('[Civico] User not logged in');

        setError(true);

        return;
      }

      const tenantVerified = await verifyTenant();

      const meeting = await loadMeeting();

      app.notifyAppLoaded();

      if (!tenantVerified) {
        setError(true);

        setErrorType('invalid-tenant');
        pages.config.setValidityState(false);
        return;
      }

      if (!meeting) {
        setError(true);
        setErrorType('invalid-meeting');
        pages.config.setValidityState(false);
        return;
      }

      setCivicoMeeting(meeting);


      pages.config.setValidityState(true);



    });
  }, []);

  return (
    <div>


      {!error && civicoMeeting && <div>
        <div>
          <p>You are adding the Civico tab to the meeting <strong>{civicoMeeting.title}</strong> scheduled for
            <strong> {(new Date(civicoMeeting.date).toLocaleString([], { minute: 'numeric', hour: 'numeric', hour12: true }))}</strong>.</p>
          <p>Press the Save button at the bottom of this window to confirm and add the Civico Hybrid tab to the meeting. </p>

        </div>
      </div>}


      {error &&
        <div>

          {errorType === 'invalid-tenant' &&
            <div>It is not possible to use the Civico Hybrid tab for this meeting as your account is not linked with a Civico Hybrid system.</div>
          }

          {errorType === 'invalid-meeting' &&
            <div>It is currently not possible to use the Civico Hybrid tab with meetings created outside of the Civico Hybrid system.</div>
          }

        </div>
      }


    </div>
  );

}
