
export default function Tab() {




  return (
    <div>
      <h1>Civico</h1>
    </div>
  );
}
